import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { DOM_STATE } from 'components/constants';
import Cta from 'components/Cta';
import { richTextElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';

export const PricingCardsWrapper = styled.div({
  ...flexColContainerStyles,
  alignItems: 'center',
  width: '100%',
  padding: `${theme.spacings.sectionSpacing} 20px`,
});

export const Title = styled.div<{ isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded }) => ({
    'h1,h2,h3,h4,h5,h6': {
      fontFamily: theme.fonts.family.main,
      fontSize: theme.fonts.size.h2,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: isSubtitleAdded ? theme.spacings.S : '36px',
      color: theme.colors.black.S900,
    },
  })
);

export const DiscountContainer = styled.div<{ visible }>(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
  marginBottom: '1.5rem',
}));

export const ToggleButtonContainer = styled.div<{
  isSecondActive: boolean;
}>(({ isSecondActive }) => ({
  position: 'relative',
  width: '224px',
  height: '38px',
  borderRadius: '24px',
  padding: '2px',
  border: '1px solid #E7E7E7',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: isSecondActive ? '24px' : '80px',
  '::before': {
    content: '""',
    position: 'absolute',
    background: theme.colors.black.S900,
    width: '105px',
    height: '34px',
    borderRadius: '24px',
    transform: isSecondActive ? 'translateX(108%)' : 'transformX(0)',
    transition: '0.3s',
    zIndex: -1,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: '32px',
  },
}));

export const Tab = styled.span<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '105px',
  height: '34px',
  cursor: 'pointer',
  color: isActive ? theme.colors.white.default : theme.colors.black.S900,
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: theme.fonts.lineHeight.p_medium,
  fontWeight: 400,
}));

export const EnterpriseFeature = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.colors.black.S900,
  width: '337px',
  height: '26px',
  justifyContent: 'center',
  borderRadius: '24px',
  marginTop: '24px',
});

export const PaymentMethodsContainer = styled.div({
  margin: '0 auto',
  marginTop: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '948px',
  gap: theme.spacings.S,
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: 'column',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gap: '32px',
    padding: '0 20px',
  },
});

export const SecurePayment = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
});

export const PaymentMethodsText = styled.p({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  color: theme.colors.gray.S500,
  marginBottom: '14px',
  fontWeight: 400,
  ':last-of-type': {
    marginBottom: 0,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
  },
});
export const IconsText = styled(PaymentMethodsText)({
  textAlign: 'right',
  [MEDIA_QUERIES.TABLET]: {
    textAlign: 'center',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: 'left',
  },
});
export const PaymentIcons = styled.div({
  opacity: 0.5,
  display: 'flex',
  gap: theme.spacings.S,
  marginBottom: theme.spacings.XS,
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: theme.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: 'space-evenly',
  },
});

export const PricingCardsContainer = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${itemsLength >= 3 ? 3 : 2}, 300px)`,
    justifyContent: 'center',
    gap: theme.spacings.S,
    width: '100%',
    maxWidth: '1024px',
    padding: '0 20px',
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: 'repeat(2, 300px)',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const PricingCardContainer = styled.div<{
  borderColorC?: string;
  extraTopSpacing?: boolean;
}>(({ borderColorC = theme.colors.neutral.N20, extraTopSpacing }) => ({
  marginTop: extraTopSpacing ? theme.spacings.M : '0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
  flexDirection: 'column',
  background: theme.colors.white.default,
  border: `1px solid ${borderColorC}`,
  alignItems: 'center',
  minHeight: '22.375rem',
  width: '18.75rem',
  borderRadius: '0.625rem',
  boxShadow: '0px 4px 16px rgba(29, 29, 31, 0.08)',
  padding: '2.25rem 1.5rem',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    margin: '0 auto',
    marginTop: extraTopSpacing ? theme.spacings.M : '0',
  },
}));

export const CardTitle = styled.h4({
  fontSize: theme.fonts.size.h4,
  lineHeight: '1.875rem',
  fontWeight: 400,
  color: theme.colors.black.S900,
  marginBottom: '2rem',
});

export const PriceWrapper = styled.span({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  fontWeight: 500,
  marginBottom: '2rem',
  height: '1.5625rem',
});

export const NewPrice = styled.p({
  fontSize: '2.25rem',
  lineHeight: 1,
  color: theme.colors.black.S900,
});

export const TimePeriod = styled.small({
  color: theme.colors.gray.default,
  fontSize: theme.fonts.size.p_small,
  fontWeight: 400,
  marginLeft: '4px',
});

export const TotalPriceWrapper = styled.p({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.gray.default,
  fontWeight: 300,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  height: '1.125rem',
  'p, strong, em': {
    margin: 0,
    color: theme.colors.gray.default,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontWeight: 300,
  },
});

export const ExtraDescription = styled.p({
  textAlign: 'center',
  fontSize: '0.875rem',
  fontWeight: 300,
  lineHeight: '1.3125rem',
  color: theme.colors.neutral.N50,
});

export const CrossedOutPrice = styled.small({
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  margin: '0 2px',
  textDecoration: 'line-through',
});

export const CrossedOutPrimaryPrice = styled.p({
  color: theme.colors.red.default,
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  textDecoration: 'line-through',
});

export const MoneyBack = styled.div({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: '#B8B8B8',
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 300,
  marginTop: '16px',
  textTransform: 'uppercase',
  height: '18px',
});

export const FeaturesList = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    display: 'flex',
    flexDirection: itemsLength % 3 === 0 ? 'row' : 'column',
    justifyContent: 'flex-start',
    rowGap: itemsLength > 3 ? theme.spacings.XS : '16px',
    columnGap: '16px',
    flexWrap: 'wrap',
    [MEDIA_QUERIES.TABLET]: {
      flexDirection: 'column',
    },
  })
);

export const FeaturesContainer = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    ...flexColContainerStyles,
    order: 1,
    justifyContent: 'center',
    padding: '36px 0',
    paddingBottom: itemsLength % 3 === 0 ? 0 : '36px',
    width: itemsLength % 3 === 0 ? '100vw' : 'auto',
    maxWidth: '910px',
    maxHeight: '362px',
    [MEDIA_QUERIES.TABLET]: {
      width: 'auto',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      margin: '0 auto',
      maxHeight: 'unset',
      width: '300px',
    },
  })
);

export const Feature = styled.div({
  display: 'flex',
  color: theme.colors.black.S900,
  fontSize: theme.fonts.size.p_small,
  p: {
    marginLeft: theme.spacings.XS,
  },
  div: {
    width: '21px',
    height: '21px',
    img: {
      width: '21px',
      height: '21px',
    },
  },
});

export const FeatureTitle = styled.p({
  fontSize: theme.fonts.size.p_medium,
  color: theme.colors.black.S900,
  fontWeight: 600,
  marginBottom: theme.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    fontSize: theme.fonts.size.h4,
    lineHeight: theme.fonts.lineHeight.h4,
    fontWeight: 400,
    textAlign: 'center',
  },
});
export const CtaContainer = styled.div({
  width: '252px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      width: '100%',
    },
  },
});

export const StyledCta = styled(Cta)<{ isButtonBlue: boolean }>(
  ({ isButtonBlue }) => ({
    position: 'relative',
    marginTop: '36px',
    marginBottom: 0,
    width: '252px',
    height: '42px',
    borderRadius: '4px',
    padding: 0,
    fontSize: theme.fonts.size.p_small,
    fontWeight: 500,
    lineHeight: 0,
    fontFamily: theme.fonts.family.main,
    svg: {
      position: 'absolute',
      right: '25px',
      transition: '0.4s',
      bottom: '13px',
      path: {
        fill: isButtonBlue
          ? theme.colors.white.default
          : theme.colors.blue.default,
      },
    },
    [DOM_STATE.HOVER]: {
      padding: 0,
      transition: '0.4s',
      borderWidth: '1px',
      opacity: 0.8,
      svg: {
        right: '20px',
        transition: '0.4s',
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      width: '252px',
    },
  })
);

export const TopLabelComponent = styled.div({
  width: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '8px',
});

export const ValidationTextComponent = styled.div({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacings.S,
  [richTextElements]: {
    color: theme.colors.neutral.N50,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontWeight: 400,
  },
  strong: {
    fontWeight: 500,
  },
  a: {
    color: themev2.colors.blue.default,
  },
});

export const CardFlag = styled.span({
  position: 'absolute',
  top: '-30px',
  paddingBottom: '4px',
  background: theme.colors.black.S900,
  width: '300px',
  height: '38px',
  zIndex: -1,
  borderTopRightRadius: '12px',
  borderTopLeftRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: themev2.fonts.size.p_small,
  fontWeight: 300,
  letterSpacing: '0.2em',
  color: theme.colors.white.default,
});

export const HideableCardFlag = hideable(CardFlag);
